body {
  background-color: #141414;
  padding: 20px;
}
#app {
  text-align: center;
  padding: 0;
  margin: 0;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.mainChat {
  text-align: center;
  font-size: 50px;
  margin: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #ffffff26;
  color: #fff;
  padding: 60px 20px;
  border-radius: 2rem;
}
.mainChat img {
  height: 100px;
  width: 100px;
  margin: 10px;

  border-radius: 0.5rem;
}

#msg2 {
  margin: 15px;
  border-radius: 0.5rem;
}
#msg3 {
  display: inline-flex;
}
#userName {
  font-size: 20px;
  font-weight: bold;
}

.msg {
  width: 100%;
  overflow-y: auto;
  padding-bottom: 31px;
  font-size: 18px;
}
.profile-pic {
  opacity: 0;
  height: 1px;
}
.message-group {
  border-bottom: solid 1px #ffffff29;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.message-group > .message:first-child .profile-pic {
  opacity: 1;
}

.message-group > .message:first-child .user-name {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.message-group > .message:first-child {
  padding-top: 2rem;
}
.message {
  display: flex;
  margin: 5px 0;
  position: relative;
}
.sent {
  justify-content: flex-end;
}

.profile-pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-content {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.user-name {
  font-weight: bold;
  display: none;
  position: absolute;
  top: 1px;
}

.text {
  padding: 10px;
  border-radius: 10px;
}

.timestamp {
  font-size: 12px;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.timestamp:hover {
  opacity: 1;
}

.sent .text {
  background-color: #3498db;
}

.received .text {
  background-color: #313c3d30;
  border: solid 1px #ffffff2b;
}

#send {
  height: 10vh;
  text-align: center;
  background-color: aliceblue;
  position: sticky;
  bottom: 1%;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}
#send p {
  color: #141414;
  font-size: 20px;
}
.hidden {
  display: none;
  padding: 4px;
  background-color: #ff00002e;
  color: white;
}
.delete {
  background-color: black;
  color: red;
}

button {
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 10px;
  cursor: pointer;
}
.load-more {
  background-color: black;
  border: solid rgba(255, 255, 255, 0.521) 1px;
  color: white;
  padding: 10px 20px;
  transition: background-color 0.3s;
}
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95.5vh;
}

.signin-content {
  background-color: #1e1e1e;
  padding: 40px;
  border-radius: 8px;
  border: solid 1px #ffffff26;
  text-align: center;
}

.signin-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #fff;
}

.signin-subtitle {
  font-size: 18px;
  color: #ccc;
  margin-bottom: 30px;
}

.signin-button {
  background-color: #4285f4;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.signin-button:hover {
  background-color: #357ae8;
}
.signin-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
